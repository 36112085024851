import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
	Navigation,
	Pagination,
	Mousewheel,
	Keyboard,
	Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import app_params from "../../config/params";
import ImageWithFallback from '../ImageWithFallback/ImageWithFallback';
import styles from './Carousel.module.css';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard, Autoplay]);

const CoverCarousel = (props) => {
	const goToRecord = (id) => (window.location.href = `${app_params.basepath}/record/${id}`);

	return (
		<div className='bg-white mx-0 mx-md-3 mt-3 mb-2 p-3 shadow-sm rounded'>
			<h5>{props.title}</h5>
			<div
				className={`${styles.mobile_drag} swiper-pag`}>
				<Swiper
					cssMode={true}
					navigation={true}
					keyboard={true}
					freeMode={false}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						// when window width is >= 640px
						480: {
							width: 480,
							slidesPerView: 3,
							spaceBetween: 30,
						},
						1024: {
							width: 1024,
							slidesPerView: 5,
							spaceBetween: 40,
						},
					}}
					autoplay={{ delay: 15000 }}
				>
					{props.items.map((item) => (
						<SwiperSlide className={`figure p-2 ${styles.cover_card}`} key={item.num_titulo} onClick={() => goToRecord(item.num_titulo)}>
							<div className={`d-flex flex-column justify-content-center ${styles.cover_container}`}>
								<ImageWithFallback fallback={`${app_params.basepath}/assets/brokenImage.png`} src={item?.portada} className={`figure-img img-fluid rounded border border-1 ${styles.cover_thumb}`} />
							</div>
							<figcaption className={`figure-caption ${styles.cover_caption}`}>
								{item.titulo}
							</figcaption>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			{props?.hasDetailPage && (
				<div className='text-center'><a href={`${app_params.basepath}/acquisitions`}>Ver todas las novedades</a></div>
			)}
		</div>
	);
};

export default CoverCarousel;
