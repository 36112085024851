import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga";

import BaseLayout from "./layouts/BaseLayout/BaseLayout";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import AdvancedSearchScreen from "./screens/AdvancedSearchScreen/AdvancedSearchScreen";
import SearchResultsScreen from "./screens/SearchResultsScreen/SearchResultsScreen";
import RecordScreen from "./screens/RecordScreen/RecordScreen";
import app_params from "./config/params";
import NotFoundScreen from "./screens/NotFoundScreen/NotFoundScreen";
import AcquisitionScreen from "./screens/AcquisitionScreen/AcquisitionScreen";

const App = () => {

  useEffect(() => {
    ReactGA.initialize(app_params.gaTag);

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename="/opac">
      <BaseLayout>
        <Routes>
          {/* Common routes */}
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/acquisitions" element={<AcquisitionScreen />} />
          <Route exact path="/advancedsearch" element={<AdvancedSearchScreen />} />
          <Route exact path="/query" element={<SearchResultsScreen />} />
          <Route path="/record/:id" element={<RecordScreen />} />
          <Route path='/404' element={<NotFoundScreen />}  />
          <Route exact path='*' element={<NotFoundScreen />} />
        </Routes>
      </BaseLayout>
    </BrowserRouter>
  );
};

export default App;
